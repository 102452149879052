body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.deliveryLookupModal {
  /*position: absolute;
  z-index: 9999;*/
  max-width: 45vw;
  /*height: calc(100vh - 200px);*/
}

.deliveryLookupModal .modal-content {
  overflow-y: auto;
  max-width: 45vw;
}

input[type=checkbox] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  padding: 5px;
  cursor: pointer;
  width: auto;
  margin-left: 7px;
  margin-right: 7px;
}

input[type=checkbox]:hover 
input[type=checkbox]:active {
  box-shadow: 0 0 2pt 1pt #5cb3fd8a;
}
.ffc {
    width: 100%;
}
.ffc-label {
    font-size: .85rem;
    margin: 0;
}
.ffc-row {
    display: flex;
    flex-direction: row;
}
.ffc-column {
    display: flex;
    flex-direction: column;
}
.ffc-error {
    color: #d9534f;
    margin-top: -2px;
    margin-bottom: -8px;
}
.ffc-warning {
    color: #ea8b23;
    margin-top: -2px;
    margin-bottom: -8px;
}
.ffc-input-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
}


.range-container {
    display: flex;
    flex-direction: row;

    margin: 0;
    padding: 0;
}

.range-field {
    display: flex;
    flex-direction: column;
    font-size: .85rem;

    margin-left: .3rem;
}

.range-field:first-child {
    margin-left: 0;
}

.filter-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    box-sizing: border-box;
}

.filter-container .rw-dropdown-list {
    width: 100%;
}

.fields-container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.field-container {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.field-settings {
    display: flex;
    flex-direction: row;

    width: 100%;
}

.field-settings > * {
    flex-grow: 1;
    margin-left: .3rem;


}

.field-label {

}

.field-settings > *:first-child {
    margin-left: 0;
}

.add-container {
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-top: 1rem;
    margin-bottom: .3rem;
}

.add-workflow {
    display: flex;
    flex-direction: row;

    width: 100%;
}

.add-btn {
    margin-left: .3rem;
}

.btn-bar {
    display: flex;
    flex-direction: row;
    justify-content: right;

    width: 100%;
}

.query-btn {
    margin-right: .3rem;
}

.filters-title {
    margin: auto;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 1rem;
}

.SignedInHeader {
    display: flex;
    flex-direction: row;
    overflow: auto;
    justify-content: flex-end;

    width: 100%;

    padding: .2rem .5rem .2rem 0;
}

.signedInCol {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.SignedInHeader Button {
    margin-left: .3rem;
}

.delivery-container {
    padding: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.instructions {
    margin-bottom: .8rem;
}

.delivery-form {

}

.delivery-form Button {
    margin-left: .3rem;
}
.delivery-form Button:first-child {
    margin-left: 0rem;
}

.delivery-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.filter {
    display: flex;
    flex-direction: row;
    margin-bottom: .3rem;
}

.date-received-field {
    width: 150%;
}

.undeliverable-field {
    margin-left: .3rem;
    flex-grow: 0;
}

.tracking-filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: .3rem;

}

.tracking-filter .form-control {
    height: auto;
}

.undeliverable-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-left: .3rem;
    margin-right: 1.3rem;

}

.fields-container > * {
    margin-top: .5rem
}

.delivery-field {
    flex-grow: 1;
    height: auto;

}

.unsaved-changes {
    text-align: center;
    display: block;
    margin-top: 1.75rem;
}

.button-bar {
    display: flex;
    flex-direction: row;

    width: 100%;

    margin-top: 3rem;
}

.divider {
    width: 100%;
    margin-top: 1.75rem;
    border-top: .0325rem solid lightGray
}

.child-container {
    width: 100%;
    margin-top: 1.75rem;
}

.recipient-search {
    width: 100%;
}

.recipient-row {
    display: flex;
    flex-direction: row;

    margin-top: .5rem;
};

.recipient-row:first-child {
    margin-top: 0;
}

.recipient-row-field {
    flex-grow: 1;

    margin-left: .5rem;
}
.recipient-row-field:first-child {
    margin-left: 0;
}

.cms {
    display: flex;
    flex-direction: column;

    width: 100%;

}

.cms .value-row {
    display: flex;
    flex-direction: row;

    margin-top: .2rem;
}

.cms .value-row:first-child {
    margin-top: 0rem;
}

.cms .value-container {
    margin-bottom: .5rem;
}

.cms input {
    font-size: .85rem;
}

.cms .remove {
    height: 2.3rem;
}

.archive-container {
    display: flex;
    flex-direction: column;

    font-size: .9rem;

    margin-left: 3rem;
    margin-right: 3rem;
}

.query-form-container {
    display: flex;
    flex-direction: row;

    margin-bottom: .8rem;
}

.query-container {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.prompt-container {
    margin-top: 3.95rem;
    margin-left: .8rem;
    width: 20%;
}

.row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row-container .ReactTable {
    width: 100%;
}

.query-button {
    align-self: flex-end;
    margin-left: .3rem;
    width: 8rem;
}

.date-field {
    margin-left: .3rem;
}
.date-field:first-child {
    margin-left: 0;
}

.query-button {

}

.download-button {

}

.download-button-dis {
    background-color: dimgray;
}

.app {
  padding: 0;
  font-size: .85rem;
}

